import React from "react"
import { insertStyledHTMLContent } from "../../tools/helper"
import { Box } from "@chakra-ui/react"
import "./WYSIWYG_Elements.css"

const WYSIWYG = ({ content, full }) => {
  // console.log("WYSIWYG CONTENT!!!!!!!!!!", content)
  return (
    <Box
      d="flex"
      justifyContent="start"
      flexDir="column"
      className={`WYSIWYG`}
      dangerouslySetInnerHTML={insertStyledHTMLContent(content, {
        allowedTags: [
          "a",
          "address",
          "article",
          "aside",
          "footer",
          "header",
          "img",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "hgroup",
          "main",
          "nav",
          "section",
          "source",
          "blockquote",
          "dd",
          "div",
          "dl",
          "dt",
          "figcaption",
          "figure",
          "picture",
          "hr",
          "li",
          "main",
          "ol",
          "p",
          "pre",
          "ul",
          "a",
          "del",
          "abbr",
          "b",
          "bdi",
          "bdo",
          "br",
          "cite",
          "code",
          "data",
          "dfn",
          "em",
          "i",
          "kbd",
          "mark",
          "q",
          "rb",
          "rp",
          "rt",
          "rtc",
          "ruby",
          "s",
          "samp",
          "small",
          "span",
          "strong",
          "sub",
          "sup",
          "time",
          "iframe",
          "u",
          "var",
          "wbr",
          "caption",
          "col",
          "colgroup",
          "table",
          "tbody",
          "td",
          "tfoot",
          "th",
          "thead",
          "tr",
          "noscript",
          "video",
        ],
        allowedAttributes: false,
        selfClosing: [
          "img",
          "br",
          "hr",
          "area",
          "base",
          "basefont",
          "input",
          "link",
          "meta",
        ],
      })}
    ></Box>
  )
}

export default WYSIWYG
