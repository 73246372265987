import { Box, Image } from "@chakra-ui/react"
import { graphql } from "gatsby"
import React from "react"
import WYSIWYG from "../components/ContentModules/WYSIWYG"
import { useSelector } from "react-redux"
import Seo from "../components/seo"

const PhoneNotice = ({ data }) => {
  const CURRENT_LANG = useSelector(state => state.currentLang)
  return (
    <>
      <Seo title="Die Seite ist leider nicht für Mobile optimiert" />
      <Box
        minHeight="100vh"
        d="flex"
        justifyContent="center"
        alignItems="center"
        pt="110px"
        pb="90px"
      >
        <Box h="100%" maxWidth="760px" display="flex" px="16px">
          <Box bg="white" p="16px" rounded="lg" className="elevation_sm">
            <Image
              src={
                data["de_DE"].acf_global_settings.mobileInhalt?.mobileTeaserMedia?.localFile?.publicURL
              }
            />
            {CURRENT_LANG !== "en" ? (
              <Box mt="4">
                <WYSIWYG
                  content={
                    data["de_DE"].acf_global_settings.mobileInhalt?.mobileTeaserText
                  }
                />
              </Box>
            ) : (
              <Box mt="4">
                <WYSIWYG
                  content={
                    data["en_US"].acf_global_settings.mobileInhalt?.mobileTeaserText
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
export const query = graphql`
  query MobileTeaserQuery {
    de_DE: wpModeSettings(
      slug: { eq: "global" }
      locale: { locale: { eq: "de_DE" } }
    ) {
      acf_global_settings {
        mobileInhalt {
          mobileTeaserText
          mobileTeaserMedia {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    en_US: wpModeSettings(
      slug: { eq: "global" }
      locale: { locale: { eq: "en_US" } }
    ) {
      acf_global_settings {
        mobileInhalt {
          mobileTeaserText
          mobileTeaserMedia {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default PhoneNotice
